<template>
  <div class="links-content">
    <div class="links-content__link" v-for="link in files" :key="link.id">
      <div class="links-content__wrap" v-for="item in setURL(link.text)" :key="item">
        <i class="icon-chain"></i>
        <a :href="item" target="_blank">{{ item }}</a>
      </div>
    </div>
  </div>
</template>

<script>
import { domainStorage } from "@/globalVariables";
import { mapActions } from "vuex";
export default {
  name: "LinksContent",
  mixins: [],
  props: {
    files: {
      type: Array,
      required: true
    }
  },
  components: {},
  data() {
    return {};
  },
  mounted() {},
  methods: {
    ...mapActions("MessengerModule", ["setVideoPlayerData"]),
    returnSrcFile(src) {
      return domainStorage + "/chat/files/" + src;
    },
    setURL(
      text,
      urlRegex = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\\/%?=~_|!:,.;]*[-A-Z0-9+&@#\\/%=~_|])/gi
    ) {
      return Array.from(new Set(text.match(urlRegex)));
    }
  },
  computed: {},
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors";
@import "~@/assets/base/breakpoints/breakpoints";

.links-content {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 24px;
  overflow-y: auto;
  margin-bottom: 60px;
  @media screen and (max-width: $xs) {
    margin-bottom: 0;
  }
  &__wrap {
    display: flex;
    padding-top: 4px;
    padding-bottom: 4px;
  }
  &__link {
    display: flex;
    flex-flow: column;
    width: 100%;
    background: #f2f7fe;
    border-radius: 4px;
    padding: 12px 16px;
    margin-bottom: 8px;
    flex-shrink: 0;
    i {
      color: #5551f9;
      margin-right: 8px;
    }
    a {
      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      line-height: 18px;
      color: #5551f9;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
</style>
